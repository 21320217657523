import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { Parser } from 'json2csv';
import {
  getEuroNCAPAdjustedRating,
  getEuroNCAPAdultOccupantScore,
  getEuroNCAPChildOccupantScore,
  getEuroNCAPSafetyAssistScore,
  getEuroNCAPVulnerableRoadUsersScore,
  getEuroNCAPCombinedScore,
  getCO2CombinedAverage,
  getCO2CombinedScore,
  getCO2Score,
  getMPGScore,
  getCombinedFuelEconomy,
  getBatteryRangeScore,
  getElectricalEfficiencyScore,
  getInsuranceGroupScore,
  getYear1SMRScore,
  getYear2SMRScore,
  getYear3SMRScore,
  getYear4SMRScore,
  getYear5SMRScore,
  getOverallSMRscore,
  getReliabilityRating,
  getReliabilityRatingScore,
  getNewCarMediaScore,
  getUsedCarMediaScore,
  getNewCarRunningCostsScore,
  getUsedCarRunningCostsScore,
  getNewCarExpertRatingScore,
  getUsedCarExpertRatingScore
} from './common/expertRatingFunctions'

export default function ReportRunningCosts() {
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const [assemblingModelData, setAssemblingModelData] = useState(true);

  const newDateRaw = new Date();
  const dateToday = convertDateFormat(newDateRaw);

  const cssoverride = React.CSSProperties = {
    display: "block",
    margin: "0 auto",
  };

  const {
    loading: loadingManModels,
    error: errorManModels,
    data: dataManModels,
  } = useQuery(gql`
    query {
      manufacturers(query: {isPublished: true}, sortBy: NAME_ASC, limit: 200) {
        _id
        name
        isPublished
      }
      models(query: {isActive: true}, limit: 1000) {
        _id
        manufacturerId
        name
        year
        prodStatus
        powertrainIsElectric
        powertrainIsPetrol
        powertrainIsDiesel
        powertrainIsHybrid
        powertrainIsPluginHybrid
        reliability
        rcDieselCO2Avg
        rcDieselCO2Min
        rcDieselCO2Max
        rcDieselMPGAvg
        rcDieselMPGMin
        rcDieselMPGMax
        rcPetrolCO2Avg
        rcPetrolCO2Min
        rcPetrolCO2Max
        rcPetrolMPGAvg
        rcPetrolMPGMin
        rcPetrolMPGMax
        rcHybridCO2Avg
        rcHybridCO2Min
        rcHybridCO2Max
        rcHybridMPGAvg
        rcHybridMPGMin
        rcHybridMPGMax
        rcPlugInHybridCO2Avg
        rcPlugInHybridCO2Min
        rcPlugInHybridCO2Max
        rcPlugInHybridBatteryRangeAvg
        rcPlugInHybridBatteryRangeMin
        rcPlugInHybridBatteryRangeMax
        rcPlugInHybridMPGAvg
        rcPlugInHybridMPGMin
        rcPlugInHybridMPGMax
        rcEVMpKWhAvg
        rcEVMpKWhMin
        rcEVMpKWhMax
        rcEVBatteryRangeAvg
        rcEVBatteryRangeMin
        rcEVBatteryRangeMax
        rcInsuranceGroupAvg
        rcInsuranceGroupMin
        rcInsuranceGroupMax
        rcAvgSMR1Year
        rcAvgSMR2Years
        rcAvgSMR3Years
        rcAvgSMR4Years
        rcAvgSMR5Years
      }
      euroncaps(query: {}, limit: 1000) {
        _id
        isValid
        date
        expiryDate
        modelId
        starRating
        adultOccupantRating
        childOccupantRating
        safetyAssistRating
        vulnerabaleRoadUsersRating
      }
      greenncaps(query: {}, limit: 1000) {
        _id
        isValid
        date
        modelId
        modelReviewed
        starRating
        cleanAirIndex
        energyEfficiencyIndex
        greenhouseGasIndex
      }
    }  
  `);

  const {
    loading: loadingModel,
    error: errorModel,
    data: dataModel,
    refetch: fetchModel,
  } = useQuery(
    gql`
      query($modelId: ObjectId) { 
        model(query: {_id: $modelId}) {
          _id
          newCarMediaRating(input: $modelId)
          usedCarMediaRating(input: $modelId)
        }
      }
    `,
    { variables: { modelId: '5c45a2f92e86592f90baf93c' } }
  );

  const [
    updateModelScoreCard,
    { loading: updateModelScoreCardLoading, error: updateModelScoreCardError },
  ] = useMutation(gql`
    mutation UpdateModelScoreCard(
      $modelId: ObjectId
      $scNewCarExpertRating: Float
      $scNewCarExpertRatingScore: String
      $scUsedCarExpertRating: Float
      $scUsedCarExpertRatingScore: String
      $scNewCarMediaRating: Float
      $scNewCarMediaRatingScore: String
      $scUsedCarMediaRating: Float
      $scUsedCarMediaRatingScore: String
      $scSafetyRating: Float
      $scSafetyRatingScore: String
      $scEcoRating: Float
      $scEcoRatingScore: String
      $scReliabilityRating: Float
      $scReliabilityRatingScore: String
      $scNewCarRunningCostRating: Float
      $scNewCarRunningCostRatingScore: String
      $scUsedCarRunningCostRating: Float
      $scUsedCarRunningCostRatingScore: String
      $scLastUpdated: String
    ) {
      updatedModel: updateOneModel(
        query: { _id: $modelId }
        set: { 
          scNewCarExpertRating: $scNewCarExpertRating
          scNewCarExpertRatingScore: $scNewCarExpertRatingScore
          scUsedCarExpertRating: $scUsedCarExpertRating
          scUsedCarExpertRatingScore: $scUsedCarExpertRatingScore
          scNewCarMediaRating: $scNewCarMediaRating
          scNewCarMediaRatingScore: $scNewCarMediaRatingScore
          scUsedCarMediaRating: $scUsedCarMediaRating
          scUsedCarMediaRatingScore: $scUsedCarMediaRatingScore
          scSafetyRating: $scSafetyRating
          scSafetyRatingScore: $scSafetyRatingScore
          scEcoRating: $scEcoRating
          scEcoRatingScore: $scEcoRatingScore
          scReliabilityRating: $scReliabilityRating
          scReliabilityRatingScore: $scReliabilityRatingScore
          scNewCarRunningCostRating: $scNewCarRunningCostRating
          scNewCarRunningCostRatingScore: $scNewCarRunningCostRatingScore
          scUsedCarRunningCostRating: $scUsedCarRunningCostRating
          scUsedCarRunningCostRatingScore: $scUsedCarRunningCostRatingScore
          scLastUpdated: $scLastUpdated
        }
      ) {
        _id
        name
      }
    }
  `);

  async function populateModelScoreCard(model) {


// console.log(model)

// console.log(model._id);
// console.log(Math.round(model.newCarExpertRating*100)/100);
// console.log(model.newCarExpertRatingScore);
// console.log(Math.round(model.usedCarExpertRating*100)/100);
// console.log(model.usedCarExpertRatingScore);
// console.log(Math.round(model.newModelMediaRating*100)/100);
// console.log(model.newModelMediaScore);
// console.log(Math.round(model.usedModelMediaRating*100)/100);
// console.log(model.usedModelMediaScore);
// console.log(Math.round(model.euroncapCombinedRatingAdjustedPercentage*100)/100);
// console.log(model.euroncapCombinedRatingScore);
// console.log(Math.round(model.rcCombinedAverageCO2Percentage*100)/100);
// console.log(model.rcCombinedAverageCO2Score);
// console.log(Math.round(model.reliabilityRatingPercentage*100)/100);
// console.log(model.reliabilityRatingScore);
// console.log(Math.round(model.newCarRunningCostsRatingPercentage*100)/100);
// console.log(model.newCarRunningCostsScore);
// console.log(Math.round(model.usedCarRunningCostsRatingPercentage*100)/100);
// console.log(model.usedCarRunningCostsScore);
// console.log(dateToday);
// const result = true;

    const result = await updateModelScoreCard({
      variables: {
        modelId: model._id,
        scNewCarExpertRating: Math.round(model.newCarExpertRating*100)/100,
        scNewCarExpertRatingScore: model.newCarExpertRatingScore,
        scUsedCarExpertRating: Math.round(model.usedCarExpertRating*100)/100,
        scUsedCarExpertRatingScore: model.usedCarExpertRatingScore,
        scNewCarMediaRating: Math.round(model.newModelMediaRating*100)/100,
        scNewCarMediaRatingScore: model.newModelMediaScore,
        scUsedCarMediaRating: Math.round(model.usedModelMediaRating*100)/100,
        scUsedCarMediaRatingScore: model.usedModelMediaScore,
        scSafetyRating: Math.round(model.euroncapCombinedRatingAdjustedPercentage*100)/100,
        scSafetyRatingScore: model.euroncapCombinedRatingScore,
        scEcoRating: Math.round(model.rcCombinedAverageCO2Percentage*100)/100,
        scEcoRatingScore: model.rcCombinedAverageCO2Score,
        scReliabilityRating: Math.round(model.reliabilityRatingPercentage*100)/100,
        scReliabilityRatingScore: model.reliabilityRatingScore,
        scNewCarRunningCostRating: Math.round(model.newCarRunningCostsRatingPercentage*100)/100,
        scNewCarRunningCostRatingScore: model.newCarRunningCostsScore,
        scUsedCarRunningCostRating: Math.round(model.usedCarRunningCostsRatingPercentage*100)/100,
        scUsedCarRunningCostRatingScore: model.usedCarRunningCostsScore,
        scLastUpdated: dateToday
      },
    });

    return result;
  }



  useEffect(() => {
    if (loadingModel === false && dataModel) {
    // console.log('result in useeffect: ',dataModel)
    }
  }, [loadingModel, dataModel]);

  useEffect(() => {
    if (updateModelScoreCardLoading === false) {
    // console.log('result in useeffect: ',dataModel)
    }
  }, [updateModelScoreCardLoading]);

  async function getModelMediaRatings(thisModelId) {

    var result = await fetchModel({ modelId: thisModelId });
    return(result.data.model);

  }

  function convertDateFormat(datevalue) {

    if(datevalue === '') return '';

    let objectDate = new Date(datevalue);

    let day = objectDate.getDate();
    let month = objectDate.getMonth() + 1; // Months are zero based
    let year = objectDate.getFullYear();

    return day + '/' + month + '/' + year;
  }

  async function prepareModelData(modelList, manufacturerList, euroNCAPList, greenNCAPList) {

    var modelListClone = JSON.parse(JSON.stringify(modelList));
    
    for (const model of modelList) {
      var mediaRatings = await getModelMediaRatings(model._id);
      if(mediaRatings.newCarMediaRating !== null ) {
        modelListClone.find(thisModel => thisModel._id === model._id).newCarMediaRating = mediaRatings.newCarMediaRating;
      } else {
        modelListClone.find(thisModel => thisModel._id === model._id).newCarMediaRating = "";
      }
      if(mediaRatings.usedCarMediaRating !== null ) {
        modelListClone.find(thisModel => thisModel._id === model._id).usedCarMediaRating = mediaRatings.usedCarMediaRating;
      } else {
        modelListClone.find(thisModel => thisModel._id === model._id).usedCarMediaRating = "";
      }
    }

    var finalModelList = assembleModelDataforExport(modelListClone, manufacturerList, euroNCAPList, greenNCAPList);

// UPDATE Model scorecards - SET if below to true to update, then set back to false
// IMPORTANT: Open console and let ALL logs complete
    for (const model of finalModelList) {

      if(false) {
        var updateModelScoreCardResult = await populateModelScoreCard(model);
        console.log('Updated scorecard: ',updateModelScoreCardResult, 'for model: ', model.manufacturerName, model.name);
      }
    }

  }

  function assembleModelDataforExport(modelList, manufacturerList, euroNCAPList, greenNCAPList) {
    console.log('Start assembling model data...', modelList.length);

    var content = 'data:text/csv;charset=utf-8,';
    var modelListClone = JSON.parse(JSON.stringify(modelList));

    let MaxRcCombinedAverageCO2 = 0;

    let MinRcInsuranceGroupAvg = 50;

    let MaxRcPetrolCO2Avg = 0;
    let MinRcPetrolCO2Avg = 1000;
    let MaxRcDieselCO2Avg = 0;
    let MinRcDieselCO2Avg = 1000;
    let MaxRcHybridCO2Avg = 0;
    let MinRcHybridCO2Avg = 1000;
    let MaxRcPlugInHybridCO2Avg = 0;
    let MinRcPlugInHybridCO2Avg = 1000;

    let MaxRcPetrolMPGAvg = 0;
    let MinRcPetrolMPGAvg = 1000;
    let MaxRcDieselMPGAvg = 0;
    let MinRcDieselMPGAvg = 1000;
    let MaxRcHybridMPGAvg = 0;
    let MinRcHybridMPGAvg = 1000;
    let MaxRcPlugInHybridMPGAvg = 0;
    let MinRcPlugInHybridMPGAvg = 1000;

    let MinCombinedFuelEconomy = 1000;
    let MaxCombinedFuelEconomy = 0;

    let MaxRcEVMpKWhAvg = 0;
    let MinRcEVMpKWhAvg = 1000;

    let MinServicingNewCarAvg = 10000000;
    let MaxServicingNewCarAvg = 1;
    let MinServicingUsedCarAvg = 10000000;
    let MaxServicingUsedCarAvg = 1;

    let MinEuroncapCombinedRatingAdjusted = 1000;
    let MaxEuroncapCombinedRatingAdjusted = 0;

    let MinReliabilityRating = 1000;
    let MaxReliabilityRating = 0;

    let MinNewCarRunningCostsRating = 1000;
    let MaxNewCarRunningCostsRating = 0;
    let MinUsedCarRunningCostsRating = 1000;
    let MaxUsedCarRunningCostsRating = 0;

    modelListClone.map((model, index) => {
      
      model.manufacturerName = manufacturerList.filter(function (element) {
        return model.manufacturerId === element._id;
      })[0].name;

      model.modelName = model.name;

      if(model.modelName === '#1') model.modelName = 'Hashtag 1'
      if(model.modelName === '#2') model.modelName = 'Hashtag 2'
      if(model.modelName === '#3') model.modelName = 'Hashtag 3'
      if(model.modelName === '#4') model.modelName = 'Hashtag 4'
      if(model.modelName === '#5') model.modelName = 'Hashtag 5'

      model.modelYear = model.year;

      let SMROverall = model.rcAvgSMR1Year + model.rcAvgSMR2Years + model.rcAvgSMR3Years + model.rcAvgSMR4Years + model.rcAvgSMR5Years

// Cap Plug-in Hybrid MPG to 100
      let cappedrcPlugInHybridMPGMin = null;
      let cappedrcPlugInHybridMPGMax = null;
      let cappedrcPlugInHybridMPGAvg = null;

      if(model.rcPlugInHybridMPGMin > 100) {
        cappedrcPlugInHybridMPGMin = 100;
      } else {
        cappedrcPlugInHybridMPGMin = model.rcPlugInHybridMPGMin;
      }

      if(model.rcPlugInHybridMPGMax > 100) {
        cappedrcPlugInHybridMPGMax = 100;
        cappedrcPlugInHybridMPGAvg = 100;
      } else {
        cappedrcPlugInHybridMPGMax = model.rcPlugInHybridMPGMax;
        cappedrcPlugInHybridMPGAvg = model.rcPlugInHybridMPGAvg;
      }

// console.log('1 - cappedrcPlugInHybridMPGMin: ',cappedrcPlugInHybridMPGMin);
// console.log('1 - cappedrcPlugInHybridMPGMax: ',cappedrcPlugInHybridMPGMax);
// console.log('1 - cappedrcPlugInHybridMPGAvg: ',cappedrcPlugInHybridMPGAvg);


      model.rcDieselCO2AvgScore = getCO2Score(model.rcDieselCO2Avg);
      model.rcDieselCO2MinScore = getCO2Score(model.rcDieselCO2Min);
      model.rcDieselCO2MaxScore = getCO2Score(model.rcDieselCO2Max);
      model.rcDieselMPGAvgScore = getMPGScore(model.rcDieselMPGAvg);
      model.rcDieselMPGMinScore = getMPGScore(model.rcDieselMPGMin);
      model.rcDieselMPGMaxScore = getMPGScore(model.rcDieselMPGMax);
      model.rcPetrolCO2AvgScore = getCO2Score(model.rcPetrolCO2Avg);
      model.rcPetrolCO2MinScore = getCO2Score(model.rcPetrolCO2Min);
      model.rcPetrolCO2MaxScore = getCO2Score(model.rcPetrolCO2Max);
      model.rcPetrolMPGAvgScore = getMPGScore(model.rcPetrolMPGAvg);
      model.rcPetrolMPGMinScore = getMPGScore(model.rcPetrolMPGMin);
      model.rcPetrolMPGMaxScore = getMPGScore(model.rcPetrolMPGMax);
      model.rcHybridCO2AvgScore = getCO2Score(model.rcHybridCO2Avg);
      model.rcHybridCO2MinScore = getCO2Score(model.rcHybridCO2Min);
      model.rcHybridCO2MaxScore = getCO2Score(model.rcHybridCO2Max);
      model.rcHybridMPGAvgScore = getMPGScore(model.rcHybridMPGAvg);
      model.rcHybridMPGMinScore = getMPGScore(model.rcHybridMPGMin);
      model.rcHybridMPGMaxScore = getMPGScore(model.rcHybridMPGMax);
      model.rcPlugInHybridCO2AvgScore = getCO2Score(model.rcPlugInHybridCO2Avg);
      model.rcPlugInHybridCO2MinScore = getCO2Score(model.rcPlugInHybridCO2Min);
      model.rcPlugInHybridCO2MaxScore = getCO2Score(model.rcPlugInHybridCO2Max);
      model.rcPlugInHybridBatteryRangeAvgScore = getBatteryRangeScore(model.rcPlugInHybridBatteryRangeAvg)
      model.rcPlugInHybridBatteryRangeMinScore = getBatteryRangeScore(model.rcPlugInHybridBatteryRangeMin)
      model.rcPlugInHybridBatteryRangeMaxScore = getBatteryRangeScore(model.rcPlugInHybridBatteryRangeMax)
      model.rcPlugInHybridMPGAvgScore = getMPGScore(cappedrcPlugInHybridMPGAvg);
      model.rcPlugInHybridMPGMinScore = getMPGScore(cappedrcPlugInHybridMPGMin);
      model.rcPlugInHybridMPGMaxScore = getMPGScore(cappedrcPlugInHybridMPGMax);

      // Combined Fuel Economy

      model.combinedFuelEconomy = (model.powertrainIsElectric === undefined ? '' : (model.powertrainIsElectric ? '' : getCombinedFuelEconomy(model.rcPetrolMPGAvg, model.rcDieselMPGAvg, model.rcHybridMPGAvg, cappedrcPlugInHybridMPGAvg)));

// console.log('model.rcPetrolMPGAvg: ',model.rcPetrolMPGAvg);
// console.log('model.rcDieselMPGAvg: ',model.rcDieselMPGAvg);
// console.log('model.rcHybridMPGAvg: ',model.rcHybridMPGAvg);
// console.log('model.rcPlugInHybridMPGAvg: ',model.rcPlugInHybridMPGAvg);
// console.log('cappedrcPlugInHybridMPGAvg: ',cappedrcPlugInHybridMPGAvg);

// let originalcombinedFE = getCombinedFuelEconomy(model.rcPetrolMPGAvg, model.rcDieselMPGAvg, model.rcHybridMPGAvg, model.rcPlugInHybridMPGAvg)
// let cappedcombinedFE = getCombinedFuelEconomy(model.rcPetrolMPGAvg, model.rcDieselMPGAvg, model.rcHybridMPGAvg, cappedrcPlugInHybridMPGAvg)

// console.log('originalcombinedFE: ',originalcombinedFE);
// console.log('cappedcombinedFE: ',cappedcombinedFE);
// console.log('======================');

      model.rcEVMpKWhAvgScore = getElectricalEfficiencyScore(model.rcEVMpKWhAvg);
      model.rcEVMpKWhMinScore = getElectricalEfficiencyScore(model.rcEVMpKWhMin);
      model.rcEVMpKWhMaxScore = getElectricalEfficiencyScore(model.rcEVMpKWhMax);
      model.rcEVBatteryRangeAvgScore = getBatteryRangeScore(model.rcEVBatteryRangeAvg);
      model.rcEVBatteryRangeMinScore = getBatteryRangeScore(model.rcEVBatteryRangeMin);
      model.rcEVBatteryRangeMaxScore = getBatteryRangeScore(model.rcEVBatteryRangeMax);
      model.rcInsuranceGroupAvgScore = getInsuranceGroupScore(model.rcInsuranceGroupAvg);
      model.rcInsuranceGroupMinScore = getInsuranceGroupScore(model.rcInsuranceGroupMin);
      model.rcInsuranceGroupMaxScore = getInsuranceGroupScore(model.rcInsuranceGroupMax);
      model.rcAvgSMR1YearScore = getYear1SMRScore(model.rcAvgSMR1Year);
      model.rcAvgSMR2YearsScore = getYear2SMRScore(model.rcAvgSMR2Years);
      model.rcAvgSMR3YearsScore = getYear3SMRScore(model.rcAvgSMR3Years);
      model.rcAvgSMR4YearsScore = getYear4SMRScore(model.rcAvgSMR4Years);
      model.rcAvgSMR5YearsScore = getYear5SMRScore(model.rcAvgSMR5Years);
      model.rcSMROverall = (SMROverall > 0 ? SMROverall : '');
      model.rcSMROverallScore = (model.rcSMROverall > 0 ? getOverallSMRscore(model.rcSMROverall) : '');

      model.rcCombinedAverageCO2 = getCO2CombinedAverage([model.rcDieselCO2Avg, model.rcPetrolCO2Avg, model.rcHybridCO2Avg, model.rcPlugInHybridCO2Avg]);
      model.rcCombinedAverageCO2Score = getCO2CombinedScore(model.rcCombinedAverageCO2);

      model.euroncap = euroNCAPList.filter(function (element) {
        return model._id === element.modelId;
      })[0];

      model.greenncap = greenNCAPList.filter(function (element) {
        return model._id === element.modelId;
      })[0];

      model.euroncapStarRating = (model.euroncap === undefined ? '' : model.euroncap.starRating);
      model.euroncapRatingDate = (model.euroncap === undefined ? '' : convertDateFormat(model.euroncap.date));
      
      model.euroncapAdultOccupantRating = (model.euroncap === undefined ? '' : model.euroncap.adultOccupantRating);
      model.euroncapAdultOccupantRatingAdjusted = (model.euroncap === undefined ? '' : getEuroNCAPAdjustedRating(model.euroncapAdultOccupantRating, model.euroncap.date));
      model.euroncapAdultOccupantScore = (model.euroncap === undefined ? '' : getEuroNCAPAdultOccupantScore(model.euroncapAdultOccupantRatingAdjusted));

      model.euroncapChildOccupantRating = (model.euroncap === undefined ? '' : model.euroncap.childOccupantRating);
      model.euroncapChildOccupantRatingAdjusted = (model.euroncap === undefined ? '' : getEuroNCAPAdjustedRating(model.euroncapChildOccupantRating, model.euroncap.date));
      model.euroncapChildOccupantScore = (model.euroncap === undefined ? '' : getEuroNCAPChildOccupantScore(model.euroncapChildOccupantRatingAdjusted));

      model.euroncapSafetyAssistRating = (model.euroncap === undefined ? '' : model.euroncap.safetyAssistRating);
      model.euroncapSafetyAssistRatingAdjusted = (model.euroncap === undefined ? '' : getEuroNCAPAdjustedRating(model.euroncapSafetyAssistRating, model.euroncap.date));
      model.euroncapSafetyAssistScore = (model.euroncap === undefined ? '' : getEuroNCAPSafetyAssistScore(model.euroncapSafetyAssistRatingAdjusted));

      model.euroncapVulnerabaleRoadUsersRating = (model.euroncap === undefined ? '' : model.euroncap.vulnerabaleRoadUsersRating);
      model.euroncapVulnerabaleRoadUsersRatingAdjusted = (model.euroncap === undefined ? '' : getEuroNCAPAdjustedRating(model.euroncapVulnerabaleRoadUsersRating, model.euroncap.date));
      model.euroncapVulnerabaleRoadUsersScore = (model.euroncap === undefined ? '' : getEuroNCAPVulnerableRoadUsersScore(model.euroncapVulnerabaleRoadUsersRatingAdjusted));

      model.euroncapCombinedRating = (model.euroncap === undefined ? '' : (model.euroncap.adultOccupantRating + model.euroncap.childOccupantRating + model.euroncap.safetyAssistRating + model.euroncap.vulnerabaleRoadUsersRating));
      model.euroncapCombinedRatingAdjusted = (model.euroncap === undefined ? '' : getEuroNCAPAdjustedRating(model.euroncapCombinedRating, model.euroncap.date));
      model.euroncapCombinedRatingScore = (model.euroncap === undefined ? '' : getEuroNCAPCombinedScore(model.euroncapCombinedRatingAdjusted));

      model.greenncapStarRating = (model.greenncap === undefined ? '' : model.greenncap.starRating);
      model.greenncapCleanAirIndex = (model.greenncap === undefined ? '' : model.greenncap.cleanAirIndex);
      model.greenncapEnergyEfficiencyIndex = (model.greenncap === undefined ? '' : model.greenncap.energyEfficiencyIndex);
      model.greenncapGreenhouseGasIndex = (model.greenncap === undefined ? '' : model.greenncap.greenhouseGasIndex);

      model.greenncapCombinedRating = (model.greenncap === undefined ? '' : (model.greenncap.cleanAirIndex + model.greenncap.energyEfficiencyIndex + model.greenncap.greenhouseGasIndex).toFixed(1));

      model.greenncapModelReviewed = (model.greenncap === undefined ? '' : model.greenncap.modelReviewed);

      model.reliabilityRating = (model.reliability === undefined ? '' : getReliabilityRating(model.reliability));
      model.reliabilityRatingScore = getReliabilityRatingScore(model.reliabilityRating)

      model.productionStatus = (model.prodStatus === undefined ? '' : model.prodStatus);

      model.newModelMediaRating = (model.newCarMediaRating === undefined ? '' : model.newCarMediaRating);

      model.newModelMediaScore = getNewCarMediaScore(model.newModelMediaRating);      
      model.usedModelMediaRating = (model.usedCarMediaRating === undefined ? '' : model.usedCarMediaRating);
      model.usedModelMediaScore = getUsedCarMediaScore(model.usedModelMediaRating);

      model.powertrainElectric = (model.powertrainIsElectric === undefined ? '' : (model.powertrainIsElectric ? 'yes' : ''));
      model.powertrainPetrol = (model.powertrainIsPetrol === undefined ? '' : (model.powertrainIsPetrol ? 'yes' : ''));
      model.powertrainDiesel = (model.powertrainIsDiesel === undefined ? '' : (model.powertrainIsDiesel ? 'yes' : ''));
      model.powertrainHybrid = (model.powertrainIsHybrid === undefined ? '' : (model.powertrainIsHybrid ? 'yes' : ''));
      model.powertrainPluginHybrid = (model.powertrainIsPluginHybrid === undefined ? '' : (model.powertrainIsPluginHybrid ? 'yes' : ''));

      model.servicingNewCarAvg = '';
      model.servicingUsedCarAvg = '';

      if(model.rcAvgSMR1Year !== null && model.rcAvgSMR2Years !== null && model.rcAvgSMR3Years !== null) {
        model.servicingNewCarAvg = (model.rcAvgSMR1Year + model.rcAvgSMR2Years + model.rcAvgSMR3Years)/3;
        model.servicingNewCarAvg = model.servicingNewCarAvg.toFixed(2);
      }

      if(model.rcAvgSMR3Years !== null && model.rcAvgSMR4Years !== null && model.rcAvgSMR5Years !== null) {
        model.servicingUsedCarAvg = (model.rcAvgSMR3Years + model.rcAvgSMR4Years + model.rcAvgSMR5Years)/3;
        model.servicingUsedCarAvg = model.servicingUsedCarAvg.toFixed(2);
      }

      // Determine min and max values for each of these
      if(model.rcCombinedAverageCO2 > MaxRcCombinedAverageCO2)
        MaxRcCombinedAverageCO2 = model.rcCombinedAverageCO2;

      if(model.rcPetrolCO2Avg > MaxRcPetrolCO2Avg)
        MaxRcPetrolCO2Avg = model.rcPetrolCO2Avg;
      if(model.rcDieselCO2Avg > MaxRcDieselCO2Avg)
        MaxRcDieselCO2Avg = model.rcDieselCO2Avg;
      if(model.rcHybridCO2Avg > MaxRcHybridCO2Avg)
        MaxRcHybridCO2Avg = model.rcHybridCO2Avg;
      if(model.rcPlugInHybridCO2Avg > MaxRcPlugInHybridCO2Avg)
        MaxRcPlugInHybridCO2Avg = model.rcPlugInHybridCO2Avg;        

      if(model.rcPetrolMPGAvg > MaxRcPetrolMPGAvg)
        MaxRcPetrolMPGAvg = model.rcPetrolMPGAvg;
      if(model.rcDieselMPGAvg > MaxRcDieselMPGAvg)
        MaxRcDieselMPGAvg = model.rcDieselMPGAvg;
      if(model.rcHybridMPGAvg > MaxRcHybridMPGAvg)
        MaxRcHybridMPGAvg = model.rcHybridMPGAvg;
      if(cappedrcPlugInHybridMPGAvg > MaxRcPlugInHybridMPGAvg)
        MaxRcPlugInHybridMPGAvg = cappedrcPlugInHybridMPGAvg;

      if(model.rcEVMpKWhAvg > MaxRcEVMpKWhAvg)
        MaxRcEVMpKWhAvg = model.rcEVMpKWhAvg;

      // Determine minimum values for each of these
      if(model.rcPetrolCO2Avg !== null) {
        if(MinRcPetrolCO2Avg > model.rcPetrolCO2Avg)
          MinRcPetrolCO2Avg = model.rcPetrolCO2Avg;
      }

      if(model.rcDieselCO2Avg !== null) {
        if(MinRcDieselCO2Avg > model.rcDieselCO2Avg)
          MinRcDieselCO2Avg = model.rcDieselCO2Avg;
      }

      if(model.rcHybridCO2Avg !== null) {
        if(MinRcHybridCO2Avg > model.rcHybridCO2Avg)
        MinRcHybridCO2Avg = model.rcHybridCO2Avg;
      }

      if(model.rcPlugInHybridCO2Avg !== null) {
        if(MinRcPlugInHybridCO2Avg > model.rcPlugInHybridCO2Avg)
        MinRcPlugInHybridCO2Avg = model.rcPlugInHybridCO2Avg; 
      }

      if(model.rcPetrolMPGAvg !== null) {
        if(MinRcPetrolMPGAvg > model.rcPetrolMPGAvg)
          MinRcPetrolMPGAvg = model.rcPetrolMPGAvg;
      }

      if(model.rcDieselMPGAvg !== null) {
        if(MinRcDieselMPGAvg > model.rcDieselMPGAvg)
          MinRcDieselMPGAvg = model.rcDieselMPGAvg;
      }

      if(model.rcHybridMPGAvg !== null) {
        if(MinRcHybridMPGAvg > model.rcHybridMPGAvg)
          MinRcHybridMPGAvg = model.rcHybridMPGAvg;
      }

      if(model.rcPlugInHybridMPGAvg !== null) {
        if(MinRcPlugInHybridMPGAvg > model.rcPlugInHybridMPGAvg)
          MinRcPlugInHybridMPGAvg = model.rcPlugInHybridMPGAvg;
      }

      if(model.rcEVMpKWhAvg !== null) {
        if(1*model.rcEVMpKWhAvg < MinRcEVMpKWhAvg)
          MinRcEVMpKWhAvg = model.rcEVMpKWhAvg;
      }

      if(model.rcInsuranceGroupAvg !== null) {
        if(model.rcInsuranceGroupAvg < MinRcInsuranceGroupAvg)
          MinRcInsuranceGroupAvg = model.rcInsuranceGroupAvg;
      }

      if(model.servicingNewCarAvg !== '') {
        if(1*model.servicingNewCarAvg > MaxServicingNewCarAvg) {
          MaxServicingNewCarAvg = 1*model.servicingNewCarAvg;
        }

        if(1*model.servicingNewCarAvg < MinServicingNewCarAvg) {
          MinServicingNewCarAvg = 1*model.servicingNewCarAvg;
        }
      }

      if(model.servicingUsedCarAvg !== '') {
        if(1*model.servicingUsedCarAvg > MaxServicingUsedCarAvg) {
          MaxServicingUsedCarAvg = 1*model.servicingUsedCarAvg;
        }

        if(1*model.servicingUsedCarAvg < MinServicingUsedCarAvg)
          MinServicingUsedCarAvg = 1*model.servicingUsedCarAvg;
      }

      if(model.euroncapCombinedRatingAdjusted !== '') {
        if(1*model.euroncapCombinedRatingAdjusted > MaxEuroncapCombinedRatingAdjusted) {
          MaxEuroncapCombinedRatingAdjusted = 1*model.euroncapCombinedRatingAdjusted;
        }

        if(1*model.euroncapCombinedRatingAdjusted < MinEuroncapCombinedRatingAdjusted)
          MinEuroncapCombinedRatingAdjusted = 1*model.euroncapCombinedRatingAdjusted;
      }

      if(model.reliabilityRating !== '') {
        if(1*model.reliabilityRating > MaxReliabilityRating) {
          MaxReliabilityRating = 1*model.reliabilityRating;
        }

        if(1*model.reliabilityRating < MinReliabilityRating)
          MinReliabilityRating = 1*model.reliabilityRating;
      }

      if(model.combinedFuelEconomy !== '') {
        if(1*model.combinedFuelEconomy > MaxCombinedFuelEconomy) {
          MaxCombinedFuelEconomy = 1*model.combinedFuelEconomy;
        }

        if(1*model.combinedFuelEconomy < MinCombinedFuelEconomy)
          MinCombinedFuelEconomy = 1*model.combinedFuelEconomy;
      }

      return true;
    });

    modelListClone.map((model) => {
      
      // Calculate percentages for eaach model
      if(model.powertrainElectric === 'yes') {
        model.rcCombinedAverageCO2Percentage = 100;
      } else {
        if(model.rcCombinedAverageCO2 === '') {
          model.rcCombinedAverageCO2Percentage = '';
        } else {
          model.rcCombinedAverageCO2Percentage = 100*(1-(model.rcCombinedAverageCO2/MaxRcCombinedAverageCO2));
          model.rcCombinedAverageCO2Percentage = model.rcCombinedAverageCO2Percentage.toFixed(2);
        }
      }

      if(model.rcEVMpKWhAvg === null) {
        model.rcEVMpKWhAvgPercentage = '';
      } else {
        model.rcEVMpKWhAvgPercentage = 100*((model.rcEVMpKWhAvg-MinRcEVMpKWhAvg)/(MaxRcEVMpKWhAvg-MinRcEVMpKWhAvg));
        model.rcEVMpKWhAvgPercentage = model.rcEVMpKWhAvgPercentage.toFixed(2);
      }
        
      if(model.rcPetrolMPGAvg === null) {
        model.rcPetrolMPGAvgPercentage = '';
      } else {
        model.rcPetrolMPGAvgPercentage = 100*((model.rcPetrolMPGAvg-MinRcPetrolMPGAvg)/(MaxRcPetrolMPGAvg-MinRcPetrolMPGAvg));
        model.rcPetrolMPGAvgPercentage = model.rcPetrolMPGAvgPercentage.toFixed(2);
      }

      if(model.rcDieselMPGAvg === null) {
        model.rcDieselMPGAvgPercentage = '';
      } else {
        model.rcDieselMPGAvgPercentage = 100*((model.rcDieselMPGAvg-MinRcDieselMPGAvg)/(MaxRcDieselMPGAvg-MinRcDieselMPGAvg));
        model.rcDieselMPGAvgPercentage = model.rcDieselMPGAvgPercentage.toFixed(2);
      }

      if(model.rcHybridMPGAvg === null) {
        model.rcHybridMPGAvgPercentage = '';
      } else {
        model.rcHybridMPGAvgPercentage = 100*((model.rcHybridMPGAvg-MinRcHybridMPGAvg)/(MaxRcHybridMPGAvg-MinRcHybridMPGAvg));
        model.rcHybridMPGAvgPercentage = model.rcHybridMPGAvgPercentage.toFixed(2);
      }

      if(model.rcPlugInHybridMPGAvg === null) {
        model.rcPlugInHybridMPGAvgPercentage = '';
      } else {

// Cap Plug-in Hybrid MPG to 100
        let cappedrcPlugInHybridMPGAvg = null;

        if(model.rcPlugInHybridMPGAvg > 100) {
          cappedrcPlugInHybridMPGAvg = 100;
          model.cappedrcPlugInHybridMPGAvg = 100;
        } else {
          cappedrcPlugInHybridMPGAvg = model.rcPlugInHybridMPGAvg;
          model.cappedrcPlugInHybridMPGAvg = model.rcPlugInHybridMPGAvg;
        }

// console.log('2 - MaxRcPlugInHybridMPGAvg: ',MaxRcPlugInHybridMPGAvg);
// console.log('2 - MinRcPlugInHybridMPGAvg: ',MinRcPlugInHybridMPGAvg);
// console.log('2 - cappedrcPlugInHybridMPGAvg: ',cappedrcPlugInHybridMPGAvg);

        model.rcPlugInHybridMPGAvgPercentage = 100*((cappedrcPlugInHybridMPGAvg-MinRcPlugInHybridMPGAvg)/(MaxRcPlugInHybridMPGAvg-MinRcPlugInHybridMPGAvg));
        model.rcPlugInHybridMPGAvgPercentage = model.rcPlugInHybridMPGAvgPercentage.toFixed(2);
      }

      if(model.rcPetrolCO2Avg === null) {
        model.rcPetrolCO2AvgPercentage = '';
      } else {
        model.rcPetrolCO2AvgPercentage = 100*(1-((model.rcPetrolCO2Avg-MinRcPetrolCO2Avg)/(MaxRcPetrolCO2Avg-MinRcPetrolCO2Avg)));
        model.rcPetrolCO2AvgPercentage = model.rcPetrolCO2AvgPercentage.toFixed(2);
      }

      if(model.rcDieselCO2Avg === null) {
        model.rcDieselCO2AvgPercentage = '';
      } else {
        model.rcDieselCO2AvgPercentage = 100*(1-((model.rcDieselCO2Avg-MinRcDieselCO2Avg)/(MaxRcDieselCO2Avg-MinRcDieselCO2Avg)));
        model.rcDieselCO2AvgPercentage = model.rcDieselCO2AvgPercentage.toFixed(2);
      }

      if(model.rcHybridCO2Avg === null) {
        model.rcHybridCO2AvgPercentage = '';
      } else {
        model.rcHybridCO2AvgPercentage = 100*(1-((model.rcHybridCO2Avg-MinRcHybridCO2Avg)/(MaxRcHybridCO2Avg-MinRcHybridCO2Avg)));
        model.rcHybridCO2AvgPercentage = model.rcHybridCO2AvgPercentage.toFixed(2);
      }

      if(model.rcPlugInHybridCO2Avg === null) {
        model.rcPlugInHybridCO2AvgPercentage = '';
      } else {
        model.rcPlugInHybridCO2AvgPercentage = 100*(1-((model.rcPlugInHybridCO2Avg-MinRcPlugInHybridCO2Avg)/(MaxRcPlugInHybridCO2Avg-MinRcPlugInHybridCO2Avg)));
        model.rcPlugInHybridCO2AvgPercentage = model.rcPlugInHybridCO2AvgPercentage.toFixed(2);
      }

      if(model.rcInsuranceGroupAvg === null) {
        model.rcInsuranceGroupAvgPercentage = '';
      } else {
        model.rcInsuranceGroupAvgPercentage = 100*(1-((model.rcInsuranceGroupAvg-MinRcInsuranceGroupAvg)/(50-MinRcInsuranceGroupAvg)));
        model.rcInsuranceGroupAvgPercentage = model.rcInsuranceGroupAvgPercentage.toFixed(2);
      }

      if(model.servicingNewCarAvg === '') {
        model.servicingNewCarAvgPercentage = '';
      } else {
        model.servicingNewCarAvgPercentage = 100*(1-((model.servicingNewCarAvg-MinServicingNewCarAvg)/(MaxServicingNewCarAvg-MinServicingNewCarAvg)));
        model.servicingNewCarAvgPercentage = model.servicingNewCarAvgPercentage.toFixed(2);
      }

      if(model.servicingUsedCarAvg === '') {
        model.servicingUsedCarAvgPercentage = '';
      } else {
        model.servicingUsedCarAvgPercentage = 100*(1-((model.servicingUsedCarAvg-MinServicingUsedCarAvg)/(MaxServicingUsedCarAvg-MinServicingUsedCarAvg)));
        model.servicingUsedCarAvgPercentage = model.servicingUsedCarAvgPercentage.toFixed(2);
      }

      if(model.euroncapCombinedRatingAdjusted === '') {
        model.euroncapCombinedRatingAdjustedPercentage = '';
      } else {
        model.euroncapCombinedRatingAdjustedPercentage = 100*((model.euroncapCombinedRatingAdjusted-MinEuroncapCombinedRatingAdjusted)/(MaxEuroncapCombinedRatingAdjusted-MinEuroncapCombinedRatingAdjusted));
        model.euroncapCombinedRatingAdjustedPercentage = model.euroncapCombinedRatingAdjustedPercentage.toFixed(2);
      }

      if(model.reliabilityRating === '') {
        model.reliabilityRatingPercentage = '';
      } else {
        model.reliabilityRatingPercentage = 100*((model.reliabilityRating-MinReliabilityRating)/(MaxReliabilityRating-MinReliabilityRating));
        model.reliabilityRatingPercentage = model.reliabilityRatingPercentage.toFixed(2);
      }

      if(model.combinedFuelEconomy === '') {
        model.combinedFuelEconomyPercentage = '';
      } else {
        model.combinedFuelEconomyPercentage = 100*((model.combinedFuelEconomy-MinCombinedFuelEconomy)/(MaxCombinedFuelEconomy-MinCombinedFuelEconomy));
        model.combinedFuelEconomyPercentage = model.combinedFuelEconomyPercentage.toFixed(2);
      }

      // Calculate Running Costs (New and Used)
      model.newCarRunningCostsRating = getNewCarRunningCostsRating(model);
      model.newCarRunningCostsScore = getNewCarRunningCostsScore(model.newCarRunningCostsRating);
      
      model.usedCarRunningCostsRating = getUsedCarRunningCostsRating(model);
      model.usedCarRunningCostsScore = getUsedCarRunningCostsScore(model.usedCarRunningCostsRating);
      
      if(model.newCarRunningCostsRating !== '') {
        if(1*model.newCarRunningCostsRating > MaxNewCarRunningCostsRating) {
          MaxNewCarRunningCostsRating = 1*model.newCarRunningCostsRating;
        }

        if(1*model.newCarRunningCostsRating < MinNewCarRunningCostsRating)
          MinNewCarRunningCostsRating = 1*model.newCarRunningCostsRating;
      }

      if(model.usedCarRunningCostsRating !== '') {
        if(1*model.usedCarRunningCostsRating > MaxUsedCarRunningCostsRating) {
          MaxUsedCarRunningCostsRating = 1*model.usedCarRunningCostsRating;
        }

        if(1*model.usedCarRunningCostsRating < MinUsedCarRunningCostsRating)
          MinUsedCarRunningCostsRating = 1*model.usedCarRunningCostsRating;
      }

// FB: Added below
      if(model.newCarRunningCostsRating === '') {
        model.newCarRunningCostsRatingPercentage = '';
      } else {
        model.newCarRunningCostsRatingPercentage = 100*((model.newCarRunningCostsRating-MinNewCarRunningCostsRating)/(MaxNewCarRunningCostsRating-MinNewCarRunningCostsRating));
        model.newCarRunningCostsRatingPercentage = model.newCarRunningCostsRatingPercentage.toFixed(2);
      }

      if(model.usedCarRunningCostsRating === '') {
        model.usedCarRunningCostsRatingPercentage = '';
      } else {
        model.usedCarRunningCostsRatingPercentage = 100*((model.usedCarRunningCostsRating-MinUsedCarRunningCostsRating)/(MaxUsedCarRunningCostsRating-MinUsedCarRunningCostsRating));
        model.usedCarRunningCostsRatingPercentage = model.usedCarRunningCostsRatingPercentage.toFixed(2);
      }
// FB: Added above

      return true;

    });

    modelListClone.map((model) => {

      // if(model.newCarRunningCostsRating === '') {
      //   model.newCarRunningCostsRatingPercentage = '';
      // } else {
      //   model.newCarRunningCostsRatingPercentage = 100*((model.newCarRunningCostsRating-MinNewCarRunningCostsRating)/(MaxNewCarRunningCostsRating-MinNewCarRunningCostsRating));
      //   model.newCarRunningCostsRatingPercentage = model.newCarRunningCostsRatingPercentage.toFixed(2);
      // }

      // if(model.usedCarRunningCostsRating === '') {
      //   model.usedCarRunningCostsRatingPercentage = '';
      // } else {
      //   model.usedCarRunningCostsRatingPercentage = 100*((model.usedCarRunningCostsRating-MinUsedCarRunningCostsRating)/(MaxUsedCarRunningCostsRating-MinUsedCarRunningCostsRating));
      //   model.usedCarRunningCostsRatingPercentage = model.usedCarRunningCostsRatingPercentage.toFixed(2);
      // }

      // Calculate Expert Rating 
      model.newCarExpertRating = getNewCarExpertRating(model);
      model.newCarExpertRatingScore = getNewCarExpertRatingScore(model.newCarExpertRating);
            
      model.usedCarExpertRating = getUsedCarExpertRating(model);
      model.usedCarExpertRatingScore = getUsedCarExpertRatingScore(model.usedCarExpertRating);

      return true;

    });

    const fields = [
      { label: 'Make', value: 'manufacturerName' },
      { label: 'Model', value: 'modelName' },
      { label: 'Model Year', value: 'modelYear' },
      { label: 'Diesel CO2 average', value: 'rcDieselCO2Avg' },
      { label: 'Diesel CO2 average percentage', value: 'rcDieselCO2AvgPercentage' },
      { label: 'Diesel CO2 average score', value: 'rcDieselCO2AvgScore' },
      { label: 'Diesel CO2 min', value: 'rcDieselCO2Min' },
      { label: 'Diesel CO2 min score', value: 'rcDieselCO2MinScore' },
      { label: 'Diesel CO2 max', value: 'rcDieselCO2Max' },
      { label: 'Diesel CO2 max score', value: 'rcDieselCO2MaxScore' },
      { label: 'Diesel MPG average', value: 'rcDieselMPGAvg' },
      { label: 'Diesel MPG average percentage', value: 'rcDieselMPGAvgPercentage' },
      { label: 'Diesel MPG average score', value: 'rcDieselMPGAvgScore' },
      { label: 'Diesel MPG min', value: 'rcDieselMPGMin' },
      { label: 'Diesel MPG min score', value: 'rcDieselMPGMinScore' },
      { label: 'Diesel MPG max', value: 'rcDieselMPGMax' },
      { label: 'Diesel MPG max score', value: 'rcDieselMPGMaxScore' },
      { label: 'Petrol CO2 average', value: 'rcPetrolCO2Avg' },
      { label: 'Petrol CO2 average percentage', value: 'rcPetrolCO2AvgPercentage' },
      { label: 'Petrol CO2 average score', value: 'rcPetrolCO2AvgScore' },
      { label: 'Petrol CO2 min', value: 'rcPetrolCO2Min' },
      { label: 'Petrol CO2 min score', value: 'rcPetrolCO2MinScore' },
      { label: 'Petrol CO2 max', value: 'rcPetrolCO2Max' },
      { label: 'Petrol CO2 max score', value: 'rcPetrolCO2MaxScore' },
      { label: 'Petrol MPG average', value: 'rcPetrolMPGAvg' },
      { label: 'Petrol MPG average percentage', value: 'rcPetrolMPGAvgPercentage' },
      { label: 'Petrol MPG average score', value: 'rcPetrolMPGAvgScore' },
      { label: 'Petrol MPG min', value: 'rcPetrolMPGMin' },
      { label: 'Petrol MPG min score', value: 'rcPetrolMPGMinScore' },
      { label: 'Petrol MPG max', value: 'rcPetrolMPGMax' },
      { label: 'Petrol MPG max score', value: 'rcPetrolMPGMaxScore' },
      { label: 'Hybrid CO2 average', value: 'rcHybridCO2Avg' },
      { label: 'Hybrid CO2 average percentage', value: 'rcHybridCO2AvgPercentage' },
      { label: 'Hybrid CO2 average score', value: 'rcHybridCO2AvgScore' },
      { label: 'Hybrid CO2 min', value: 'rcHybridCO2Min' },
      { label: 'Hybrid CO2 min score', value: 'rcHybridCO2MinScore' },
      { label: 'Hybrid CO2 max', value: 'rcHybridCO2Max' },
      { label: 'Hybrid CO2 max score', value: 'rcHybridCO2MaxScore' },
      { label: 'Hybrid MPG average', value: 'rcHybridMPGAvg' },
      { label: 'Hybrid MPG average percentage', value: 'rcHybridMPGAvgPercentage' },
      { label: 'Hybrid MPG average score', value: 'rcHybridMPGAvgScore' },
      { label: 'Hybrid MPG min', value: 'rcHybridMPGMin' },
      { label: 'Hybrid MPG min score', value: 'rcHybridMPGMinScore' },
      { label: 'Hybrid MPG max', value: 'rcHybridMPGMax' },
      { label: 'Hybrid MPG max score', value: 'rcHybridMPGMaxScore' },
      { label: 'Plug-in Hybrid CO2 average', value: 'rcPlugInHybridCO2Avg' },
      { label: 'Plug-in Hybrid CO2 average percentage', value: 'rcPlugInHybridCO2AvgPercentage' },
      { label: 'Plug-in Hybrid CO2 average score', value: 'rcPlugInHybridCO2AvgScore' },
      { label: 'Plug-in Hybrid CO2 min', value: 'rcPlugInHybridCO2Min' },
      { label: 'Plug-in Hybrid CO2 min score', value: 'rcPlugInHybridCO2MinScore' },
      { label: 'Plug-in Hybrid CO2 max', value: 'rcPlugInHybridCO2Max' },
      { label: 'Plug-in Hybrid CO2 max score', value: 'rcPlugInHybridCO2MaxScore' },
      { label: 'Plug-in Hybrid battery range average', value: 'rcPlugInHybridBatteryRangeAvg' },
      { label: 'Plug-in Hybrid battery range average score', value: 'rcPlugInHybridBatteryRangeAvgScore' },
      { label: 'Plug-in Hybrid battery range min', value: 'rcPlugInHybridBatteryRangeMin' },
      { label: 'Plug-in Hybrid battery range min score', value: 'rcPlugInHybridBatteryRangeMinScore' },
      { label: 'Plug-in Hybrid battery range max', value: 'rcPlugInHybridBatteryRangeMax' },
      { label: 'Plug-in Hybrid battery range max score', value: 'rcPlugInHybridBatteryRangeMaxScore' },
      { label: 'Plug-in Hybrid MPG average', value: 'rcPlugInHybridMPGAvg' },
      { label: 'Plug-in Hybrid MPG average (capped)', value: 'cappedrcPlugInHybridMPGAvg' },
      { label: 'Plug-in Hybrid MPG average percentage', value: 'rcPlugInHybridMPGAvgPercentage' },
      { label: 'Plug-in Hybrid MPG average score', value: 'rcPlugInHybridMPGAvgScore' },
      { label: 'Plug-in Hybrid MPG min', value: 'rcPlugInHybridMPGMin' },
      { label: 'Plug-in Hybrid MPG min score', value: 'rcPlugInHybridMPGMinScore' },
      { label: 'Plug-in Hybrid MPG max', value: 'rcPlugInHybridMPGMax' },
      { label: 'Plug-in Hybrid MPG max score', value: 'rcPlugInHybridMPGMaxScore' },
      { label: 'Combined Fuel Economy', value: 'combinedFuelEconomy' },
      { label: 'Combined Fuel Economy percentage', value: 'combinedFuelEconomyPercentage' },
      { label: 'EV m/KWh average', value: 'rcEVMpKWhAvg' },
      { label: 'EV m/KWh average percentage', value: 'rcEVMpKWhAvgPercentage' },
      { label: 'EV m/KWh average score', value: 'rcEVMpKWhAvgScore' },
      { label: 'EV m/KWh min', value: 'rcEVMpKWhMin' },
      { label: 'EV m/KWh min score', value: 'rcEVMpKWhMinScore' },
      { label: 'EV m/KWh max', value: 'rcEVMpKWhMax' },
      { label: 'EV m/KWh max score', value: 'rcEVMpKWhMaxScore' },
      { label: 'EV battery range average', value: 'rcEVBatteryRangeAvg' },
      { label: 'EV battery range average score', value: 'rcEVBatteryRangeAvgScore' },
      { label: 'EV battery range min', value: 'rcEVBatteryRangeMin' },
      { label: 'EV battery range min score', value: 'rcEVBatteryRangeMinScore' },
      { label: 'EV battery range max', value: 'rcEVBatteryRangeMax' },
      { label: 'EV battery range max score', value: 'rcEVBatteryRangeMaxScore' },
      { label: 'CO2 combined average', value: 'rcCombinedAverageCO2' },
      { label: 'Eco rating score', value: 'rcCombinedAverageCO2Score' },
      { label: 'CO2 combined average percentage', value: 'rcCombinedAverageCO2Percentage' },
      { label: 'Insurance group average', value: 'rcInsuranceGroupAvg' },
      { label: 'Insurance group average percent', value: 'rcInsuranceGroupAvgPercentage' },
      { label: 'Insurance group average score', value: 'rcInsuranceGroupAvgScore' },
      { label: 'Insurance group min', value: 'rcInsuranceGroupMin' },
      { label: 'Insurance group min score', value: 'rcInsuranceGroupMinScore' },
      { label: 'Insurance group max', value: 'rcInsuranceGroupMax' },
      { label: 'Insurance group max score', value: 'rcInsuranceGroupMaxScore' },
      { label: 'Average SMR for 1 year', value: 'rcAvgSMR1Year' },
      { label: 'Average SMR for 1 year score', value: 'rcAvgSMR1YearScore' },
      { label: 'Average SMR for 2 years', value: 'rcAvgSMR2Years' },
      { label: 'Average SMR for 2 years score', value: 'rcAvgSMR2YearsScore' },
      { label: 'Average SMR for 3 years', value: 'rcAvgSMR3Years' },
      { label: 'Average SMR for 3 years score', value: 'rcAvgSMR3YearsScore' },
      { label: 'Average SMR for 4 years', value: 'rcAvgSMR4Years' },
      { label: 'Average SMR for 4 years score', value: 'rcAvgSMR4YearsScore' },
      { label: 'Average SMR for 5 years', value: 'rcAvgSMR5Years' },
      { label: 'Average SMR for 5 years score', value: 'rcAvgSMR5YearsScore' },
      { label: 'SMR Overall', value: 'rcSMROverall' },
      { label: 'SMR Overall score', value: 'rcSMROverallScore' },
      { label: 'EuroNCAP star rating', value: 'euroncapStarRating' },
      { label: 'EuroNCAP rating date', value: 'euroncapRatingDate' },
      { label: 'EuroNCAP adult occupant rating', value: 'euroncapAdultOccupantRating' },
      { label: 'EuroNCAP adult occupant rating adjusted', value: 'euroncapAdultOccupantRatingAdjusted' },
      { label: 'EuroNCAP adult occupant score', value: 'euroncapAdultOccupantScore' },
      { label: 'EuroNCAP child occupant rating', value: 'euroncapChildOccupantRating' },
      { label: 'EuroNCAP child occupant rating adjusted', value: 'euroncapChildOccupantRatingAdjusted' },
      { label: 'EuroNCAP child occupant score', value: 'euroncapChildOccupantScore' },
      { label: 'EuroNCAP safety assist rating', value: 'euroncapSafetyAssistRating' },
      { label: 'EuroNCAP safety assist rating adjusted', value: 'euroncapSafetyAssistRatingAdjusted' },
      { label: 'EuroNCAP safety assist score', value: 'euroncapSafetyAssistScore' },
      { label: 'EuroNCAP vulnerabale road users rating', value: 'euroncapVulnerabaleRoadUsersRating' },
      { label: 'EuroNCAP vulnerabale road users rating', value: 'euroncapVulnerabaleRoadUsersRatingAdjusted' },
      { label: 'EuroNCAP vulnerabale road users rating', value: 'euroncapVulnerabaleRoadUsersScore' },
      { label: 'EuroNCAP combined rating', value: 'euroncapCombinedRating' },
      { label: 'EuroNCAP combined rating adjusted', value: 'euroncapCombinedRatingAdjusted' },
      { label: 'EuroNCAP combined rating score', value: 'euroncapCombinedRatingScore' },
      { label: 'EuroNCAP combined rating adjusted percentage', value: 'euroncapCombinedRatingAdjustedPercentage' },
      { label: 'GreenNCAP star rating', value: 'greenncapStarRating' },
      { label: 'GreenNCAP clean air index', value: 'greenncapCleanAirIndex' },
      { label: 'GreenNCAP energy efficiency index', value: 'greenncapEnergyEfficiencyIndex' },
      { label: 'GreenNCAP greenhouse gas index', value: 'greenncapGreenhouseGasIndex' },
      { label: 'GreenNCAP combined rating', value: 'greenncapCombinedRating' },
      { label: 'GreenNCAP model reviewed', value: 'greenncapModelReviewed' },
      { label: 'Reliability rating', value: 'reliabilityRating' },
      { label: 'Reliability rating score', value: 'reliabilityRatingScore' },
      { label: 'Reliability rating percentage', value: 'reliabilityRatingPercentage' },
      { label: 'Production status', value: 'productionStatus' },
      { label: 'New Car Media score', value: 'newModelMediaScore' },
      { label: 'New Car Media rating', value: 'newModelMediaRating' },
      { label: 'Used Car Media score', value: 'usedModelMediaScore' },
      { label: 'Used Car Media rating', value: 'usedModelMediaRating' },
      { label: 'Powertrain: Electric', value: 'powertrainElectric' },
      { label: 'Powertrain: Petrol', value: 'powertrainPetrol' },
      { label: 'Powertrain: Diesel', value: 'powertrainDiesel' },
      { label: 'Powertrain: Hybrid', value: 'powertrainHybrid' },
      { label: 'Powertrain: PluginHybrid', value: 'powertrainPluginHybrid' },
      { label: 'Servicing: New Car (avg years 1 to 3)', value: 'servicingNewCarAvg' },
      { label: 'Servicing: New Car percent', value: 'servicingNewCarAvgPercentage' },
      { label: 'Servicing: Used Car (avg years 3 to 5)', value: 'servicingUsedCarAvg' },
      { label: 'Servicing: Used Car percent', value: 'servicingUsedCarAvgPercentage' },
      { label: 'New Car Running Cost rating', value: 'newCarRunningCostsRating' },
      { label: 'New Car Running Cost score', value: 'newCarRunningCostsScore' },
      { label: 'New Car Running Cost rating percentage', value: 'newCarRunningCostsRatingPercentage' },
      { label: 'Used Car Running Cost rating', value: 'usedCarRunningCostsRating' },
      { label: 'Used Car Running Cost score', value: 'usedCarRunningCostsScore' },
      { label: 'Used Car Running Cost rating percentage', value: 'usedCarRunningCostsRatingPercentage' },
      { label: 'New Car Expert Rating score', value: 'newCarExpertRatingScore' },
      { label: 'New Car Expert Rating', value: 'newCarExpertRating' },
      { label: 'Used Car Expert Rating score', value: 'usedCarExpertRatingScore' },
      { label: 'Used Car Expert Rating', value: 'usedCarExpertRating' },
    ];

    const opts = { fields };
    const parser = new Parser(opts);
    const csv = parser.parse(modelListClone);
    content += csv;

    // console.log('ready to download');
    window.open(encodeURI(content));

    setAssemblingModelData(false);

    return modelListClone;
  }

  function getNewCarRunningCostsRating(thisModel) {

    let thisModelNewCarRunningCosts = 0;

    // UPDATE THESE FROM WORKBOOK: Average percentages (for use when data not available for a model)	
    let thisModelCombinedFuelEconomy = 36;
    let thisModelEVMpKWhAvg = 32.1;
    let thisModelCO2Avg = 67.6;
    let thisModelInsuranceAvg = 	34.8;
    let thisModelServicingNewCarAvg = 69.5;

    if(thisModel.combinedFuelEconomyPercentage !== '')
      thisModelCombinedFuelEconomy = 0.3*thisModel.combinedFuelEconomyPercentage;

    if(thisModel.rcEVMpKWhAvgPercentage !== '')
      thisModelEVMpKWhAvg = 1*thisModel.rcEVMpKWhAvgPercentage;

    if(thisModel.powertrainIsElectric === 'yes') {
      thisModelCO2Avg = 100;
    } else {
      if(thisModel.rcCombinedAverageCO2Percentage !== '') {
        thisModelCO2Avg = 1*thisModel.rcCombinedAverageCO2Percentage; 
      }
    }    

    if(thisModel.rcInsuranceGroupAvgPercentage !== '')
      thisModelInsuranceAvg = 1*thisModel.rcInsuranceGroupAvgPercentage;

    if(thisModel.servicingNewCarAvgPercentage !== '')
      thisModelServicingNewCarAvg = 1*thisModel.servicingNewCarAvgPercentage;

    if(thisModel.powertrainIsElectric === 'yes') {
      thisModelNewCarRunningCosts = ((thisModelEVMpKWhAvg*4)+(thisModelCO2Avg*2)+(thisModelInsuranceAvg*3)+thisModelServicingNewCarAvg)/10;
    } else {
        thisModelNewCarRunningCosts = ((thisModelCombinedFuelEconomy*4)+(thisModelCO2Avg*2)+(thisModelInsuranceAvg*3)+thisModelServicingNewCarAvg)/10;
    }

    thisModelNewCarRunningCosts = thisModelNewCarRunningCosts.toFixed(2);

    return thisModelNewCarRunningCosts;
  }

  function getUsedCarRunningCostsRating(thisModel) {

    let thisModelUsedCarRunningCosts = 0;

    // UPDATE THESE FROM WORKBOOK: Average percentages (for use when data not available for a model)	
    let thisModelCombinedFuelEconomy = 36;
    let thisModelEVMpKWhAvg = 32.1;
    let thisModelCO2Avg = 67.6;
    let thisModelInsuranceAvg = 	34.8;
    let thisModelServicingUsedCarAvg = 66.3;

    if(thisModel.combinedFuelEconomyPercentage !== '')
      thisModelCombinedFuelEconomy = 0.3*thisModel.combinedFuelEconomyPercentage;

    if(thisModel.rcEVMpKWhAvgPercentage !== '')
      thisModelEVMpKWhAvg = 1*thisModel.rcEVMpKWhAvgPercentage;

    if(thisModel.powertrainIsElectric === 'yes') {
      thisModelCO2Avg = 100;
    } else {
      if(thisModel.rcCombinedAverageCO2Percentage !== '') {
        thisModelCO2Avg = 1*thisModel.rcCombinedAverageCO2Percentage; 
      }
    }
  
    if(thisModel.rcInsuranceGroupAvgPercentage !== '')
      thisModelInsuranceAvg = 1*thisModel.rcInsuranceGroupAvgPercentage;

    if(thisModel.servicingUsedCarAvgPercentage !== '')
    thisModelServicingUsedCarAvg = 1*thisModel.servicingUsedCarAvgPercentage;

    if(thisModel.powertrainIsElectric === 'yes') {
      thisModelUsedCarRunningCosts = ((thisModelEVMpKWhAvg*3)+thisModelCO2Avg+(thisModelInsuranceAvg*4)+(thisModelServicingUsedCarAvg*2))/10;
    } else {
        thisModelUsedCarRunningCosts = ((thisModelCombinedFuelEconomy*3)+thisModelCO2Avg+(thisModelInsuranceAvg*4)+(thisModelServicingUsedCarAvg*2))/10;
    }

    thisModelUsedCarRunningCosts = thisModelUsedCarRunningCosts.toFixed(2);

    return thisModelUsedCarRunningCosts;
  }

  function getNewCarExpertRating(thisModel) {

    let newCarExpertRating = 0;

    // UPDATE THESE FROM WORKBOOK: Average percentages (for use when data not available for a model)	  
    let thisModelNewModelMediaRating = 65.1;
    let thisModelEuroncapCombinedRatingAdjustedPercentage = 63.9; // Safety rating
    let thisModelRcCombinedAverageCO2Percentage = 67.6; // Eco rating
    let thisModelReliabilityRatingPercentage = 64.7;

    if(thisModel.newModelMediaRating !== '')
      thisModelNewModelMediaRating = 1*thisModel.newModelMediaRating;

    if(thisModel.euroncapCombinedRatingAdjustedPercentage !== '')
      thisModelEuroncapCombinedRatingAdjustedPercentage = 1*thisModel.euroncapCombinedRatingAdjustedPercentage;

    if(thisModel.rcCombinedAverageCO2Percentage !== '')
      thisModelRcCombinedAverageCO2Percentage = 1*thisModel.rcCombinedAverageCO2Percentage;

    if(thisModel.reliabilityRatingPercentage !== '')
      thisModelReliabilityRatingPercentage = 1*thisModel.reliabilityRatingPercentage;

    newCarExpertRating = ((thisModelNewModelMediaRating*7)+(thisModelEuroncapCombinedRatingAdjustedPercentage*7)+thisModelRcCombinedAverageCO2Percentage+thisModelReliabilityRatingPercentage+(thisModel.newCarRunningCostsRatingPercentage*4))/20;

    newCarExpertRating = newCarExpertRating.toFixed(2);

    return newCarExpertRating;
  }

  function getUsedCarExpertRating(thisModel) {

    let usedCarExpertRating = 0;

    let thisModelUsedModelMediaRating = 69.0;
    let thisModelEuroncapCombinedRatingAdjustedPercentage = 63.4; // Safety rating
    let thisModelRcCombinedAverageCO2Percentage = 67.6; // Eco rating
    let thisModelReliabilityRatingPercentage = 64.7;

    if(thisModel.usedModelMediaRating !== '')
      thisModelUsedModelMediaRating = 1*thisModel.usedModelMediaRating;

    if(thisModel.euroncapCombinedRatingAdjustedPercentage !== '')
      thisModelEuroncapCombinedRatingAdjustedPercentage = 1*thisModel.euroncapCombinedRatingAdjustedPercentage;

    if(thisModel.rcCombinedAverageCO2Percentage !== '')
      thisModelRcCombinedAverageCO2Percentage = 1*thisModel.rcCombinedAverageCO2Percentage;

    if(thisModel.reliabilityRatingPercentage !== '')
      thisModelReliabilityRatingPercentage = 1*thisModel.reliabilityRatingPercentage;

    usedCarExpertRating = ((thisModelUsedModelMediaRating*5)+(thisModelEuroncapCombinedRatingAdjustedPercentage*5)+thisModelRcCombinedAverageCO2Percentage+(thisModelReliabilityRatingPercentage*3)+(thisModel.usedCarRunningCostsRatingPercentage*6))/20;
    usedCarExpertRating = usedCarExpertRating.toFixed(2);

    return usedCarExpertRating;
  }

  useEffect(() => {

    if (loadingManModels === false && dataManModels) {
      setManufacturers(dataManModels.manufacturers);
      setModels(dataManModels.models);
      prepareModelData(dataManModels.models, dataManModels.manufacturers, dataManModels.euroncaps, dataManModels.greenncaps);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadingManModels,
    dataManModels,
  ]);

  if (errorManModels) {
    return <div>encountered an error: {errorManModels}</div>;
  }

  if (errorModel) {
    return <div>encountered an error: {errorModel}</div>;
  }

  if (updateModelScoreCardError) {
    return <p>Error: {updateModelScoreCardError}</p>;
  }

  return (
    <>
      <p color='primary'>Running model running costs report...</p>
      {loadingManModels ? (
        <>
          <p>
            Step 1 of 3: Pulling in active manufacturers and models...
          </p>
          <ClipLoader
            loading={true}
            cssOverride={cssoverride}
          />
        </>
      ) : (
        <>
          <p style={{ fontWeight: 'bold' }}>
            Step 1 of 3: Active manufacturers and models loaded.
          </p>
          <p>Pulled {manufacturers.length} published manufacturers</p>
          <p>Pulled {models.length} active models</p>
        </>
      )}
      {assemblingModelData ? (
        <>
          <p>Step 2 of 3: Assembling model data for export...</p>
          <ClipLoader
            loading={true}
            cssOverride={cssoverride}
          />
        </>
      ) : (
        <>
          <p style={{ fontWeight: 'bold' }}>
            Step 3 of 3: All model data ready — download file
          </p>
        </>
      )}
    </>
  );
}
